.glance-bar {
	p {
		margin: 0;
		margin-right: 20px;
	}
}

.at-a-glance-stat {
	margin-right: 20px;

	svg {
		vertical-align: bottom;
	}
}

.p-datatable-tbody.p-virtualscroller-content {
	padding-top: 16px;
}

// .app-content {
// 	overflow-y: auto;
// 	padding-bottom: 0px;
// }

.p-virtualscroller {
	height: calc(100vh - 220px) !important;
	margin-bottom: 0px;
}

.p-column-filter {
	max-width: none;
}

.p-column-filter-clear-button {
	display: none;
}

#rating-column .p-inputtext {
	width: 100%;
	text-align: center;
}