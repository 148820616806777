$sidebar-bg-color: rgb(255, 255, 255);
$sidebar-color: #111;
$highlight-color: gray;
$submenu-bg-color: rgba(0, 0, 0, 0.05);
$submenu-bg-color-collapsed: rgb(255, 255, 255);
$icon-bg-color: #fff;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.App {
	text-align: center;
}

.App-logo {
	height: 80px;
	pointer-events: none;
	margin-right: 10px;
	margin-left: 16px;
}

.App-header {
	background-color: white;
	background-image: url('./img/bg.jpeg');
	min-height: 100vh;
	font-size: calc(10px + 2vmin);
	color: #222;

	a {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	h2 {
		text-align: left;
		margin: 0;
		margin-top: 5px;
		color: white;
		font-family: 'Montserrat', sans-serif;
		font-weight: 300;
		font-size: 1.3rem;
		letter-spacing: 0.7rem;
	}

	button {
		margin: auto;
		margin-top: 50px;
		background: white;
		color: #333;
		font-weight: 600;
		border: 0px;
		width: 240px;
		height: 50px;
		text-align: center;
		font-size: 18px;
		display: flex;
		justify-items: center;

		img {
			height: 30px;
			margin-right: 12px;
		}
	}

	.box {
		background: #7893AE;
		height: 100vh;
		float: right;
		width: 500px;
		padding: 20px 20px 50px 20px;
	}

	.p-button {
		&:hover {
			background-color: white;
			color: inherit;
		}
	}
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: var(--surface-ground);
	font-family: 'Open Sans', sans-serif;
	color: var(--text-color);
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.hidden {
	display: none;
}

$border-radius: 10px;

// Top Nav
$topbar-height: 70px;

.topbar {
	height: $topbar-height;
	width: 100%;
	background: #7893AE;
	color: white;
	z-index: 1000;
	padding-left: 2em;
	padding-top: 10px;
	border-bottom: 1px solid var(--surface-border);

	h2 {
		margin: 0;
		text-transform: uppercase;
		font-family: 'Montserrat', sans-serif;
		font-weight: 400;
		letter-spacing: 0.5rem;
		font-size: 1.2rem;
	}

	img {
		height: 50px;
		margin-right: 12px;
	}
}

.topbar-dark {
	background: var(--surface-card)
}

// Side Nav
.side-nav {
	width: 240px;
	height: calc(100vh - #{$topbar-height + 40px});
	padding: 0;
	margin-top: 20px;
	margin-left: 10px;
	border-radius: $border-radius;

	.p-card-body {
		padding: 0;
		margin-top: 0;
	}

	h4 {
		padding-left: 1.2em;
		margin-bottom: 5px;
		margin-top: 15px;
	}

	a {
		color: rgb(73, 80, 87);
	}
}

.layout-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li a,
	li div {
		display: inline-block;
		padding: 0.8em;
		width: 100%;
		padding-left: 1.8em;
		text-decoration: none;

		&:visited {
			color: inherit;
		}

		i {
			font-size: 18px;
			vertical-align: middle;
		}

		span {
			margin-left: 0.7em;
			vertical-align: middle;
			line-height: 18px;
			display: inline-block;
			font-size: 16px;
		}

		&:hover {
			background-color: #eee;
			cursor: pointer;
		}
	}

	li div {
		&:hover {
			background-color: inherit;
			cursor: default;
		}
	}
}

// App content
.app-content {
	padding: 1em;
	padding-top: 0em;
	padding-bottom: 0.7em;
	flex: 1;
	height: calc(100vh - #{$topbar-height});
	overflow-y: scroll;
}

.p-datatable-header {

	border-top: none !important;

	.table-header {
		display: flex;
		justify-content: space-between;
		padding: 0rem;
		text-align: left;
		font-size: 1.5rem;
	}
}

.p-datatable table {
	width: 100%;
}

.p-column-filter {
	max-width: 120px;
}

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

td .p-dropdown {
	position: absolute;
	// margin-top: -17px;
}

.p-column-title i {
	font-size: 1.3rem;
}

.card-row {
	display: flex;

	.p-card {
		flex: 1;
		margin: 10px;

		.p-card-content {
			padding: 0px;
		}
	}
}

.p-d-block {
	display: block;
}

.p-field {
	label {
		margin-bottom: 5px;
	}

	margin-bottom: 20px;
}

.dashboard-mini-box {
	flex: 1;
	text-align: center;
	margin: 5px;

	div {
		flex: 1;
		align-items: flex-end;
		border-radius: 5px;
		text-align: center;

		h4,
		h1,
		p {
			margin-top: 0px;
			margin-bottom: 5px;
		}
	}
}

.container-full-vh {
	height: calc(100vh - 95px);
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.follower-column {
	justify-content: center !important;
}

.layout-menu {
	li .active {
		background-color: rgba(27, 61, 88, 0.13);
	}
}

.letter-icon {
	background: #495057;
	border-radius: 50%;
	width: 20px;
	line-height: 20px;
	display: inline-block;
	color: white;
	text-align: center;
	font-weight: normal;
	font-size: 0.8rem;
	margin-left: 20px;
}

.dash-card {
	padding: 20px;
	border-radius: 10px;
	margin: 5px;
	flex-grow: 1;
	width: 33%;
	max-height: 40vh;
}

.dash-card h3 {
	margin: 0;
}

// .trend-card .p-datatable .p-datatable-thead>tr>th {
// 	display: none;
// }

.sfa-drop {
	max-width: 350px !important;
	min-width: 200px !important;
	display: inline-block;
	height: 40px !important;
	// .file-types {
	// 	display: none;
	// }
}

.side-nav svg {
	vertical-align: middle;
	height: 19px;
	width: 19px;
}

.p-column-header-content svg {
	vertical-align: middle;
}

.p-card,
.p-datatable {
	border: 1px solid var(--surface-border);
	border-radius: 4px;
	box-shadow: none;
}

.p-toolbar {
	padding: 12px;
}

button svg {
	margin-right: 5px;
	margin-top: 1px;
}

.p-column-header-content {
	cursor: default;
}

.result-card p,
.result-card h3 {
	margin: 5px;
	line-height: 1.5;
}

.kwe-chart-container .p-chart {
	height: 300px;
}

.page-header {
	display: flex;
	width: 100%;
	margin-left: -1em;
	margin-right: -1em;
	background: var(--surface-card);
	padding: 20px;
	margin-bottom: 15px;
	border-bottom: 1px solid var(--surface-border);

	h2 {
		margin: 0
	}
}