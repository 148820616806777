h4 {
	margin-top: 10px;
	margin-bottom: 5px;
}

.shadow-2 {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

a {
	text-decoration: none !important;
}

.container .p-card:hover {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.card-container {
	display: flex;
	flex-wrap: wrap;
}

.container {
	margin-top: 3px;
	padding: 30px;
	padding-top: 20px;
}

.p-accordion-header-link {
	background: var(--surface-card) !important;
	border: 1px solid var(--surface-border) !important
}

.p-accordion-content {
	background: var(--surface-ground) !important;
	border: none !important;
}

.playlist-card {
	padding: 0;

	p {
		margin: 0
	}

	img {
		border-radius: 2px;
	}

	.p-card {
		padding: 0;
	}

	.p-card-content {
		padding: 0;
	}

	.p-card-body {
		padding: 5px;
	}
}

.tp-card-background {
	background-color: var(--surface-d);
}

.playlist-card-container {
	// width: 125px;
	transition: 0.3s;

	&:hover {
		width: 125px;
	}
}

.playlist-card-parent {
	width: 300px;

	&:hover {
		width: 300px;
	}
}

// .p-inputnumber-input {
// 	padding: 3px;
// 	margin-right: 15px;
// 	margin-left: -8px;
// }

.cancel-icon {
	color: inherit;

	&:hover {
		color: red;
		cursor: pointer;
	}
}

.click-icon {
	cursor: pointer;
}