h4 {
	margin-top: 10px;
	margin-bottom: 5px;
}

.shadow-2 {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

a {
	text-decoration: none !important;
}

.container .p-card:hover {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.container .p-card {
	transition: 0.15s;
}

.max-800-container {
	width: 100%;
	max-width: 800px;
}

.number-col {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px 0 20px;
}

.info-col {
	margin-left: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cover-col {
	width: 50px;
	height: 50px;
}

.primary-dropdown {
	width: 100%;
	margin-top: 14px;
	max-width: 400px;
}

.switch-container {
	display: flex;

	div:nth-child(1) {
		display: flex;
		align-items: center;
		margin-right: 0px;
	}

	div:nth-child(2) {
		flex: 1;
	}

	p {
		margin: 15px;
	}
}