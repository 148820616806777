.sidebar-profile-pic {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	margin-right: 15px;
	margin-top: 5px;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 0px 10px rgb(86, 135, 184)
	}
}

.sidebar-profile-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-left: 14px;
	margin-bottom: 7px;
	margin-top: 8px;
	transition: 0.4s;

	p {
		padding-left: 0px;
		margin-bottom: 0px;
		margin-top: 7px;
	}
}

.sidebar-team {
	padding-left: 0px;
	margin-top: 0px
}

.sidebar-footer {
	width: 100%;
	text-align: right;
	margin-top: 15px;
	margin-bottom: 10px;
	padding-right: 24px;
	cursor: pointer;
}

.pro-sidebar {
	color: var(--text-color);
	border-right: 1px solid var(--surface-border);

	.pro-sidebar-inner {
		background: var(--surface-card)
	}

	.pro-sidebar-header {
		border-bottom: 1px solid var(--surface-border) !important;
	}

	.pro-icon-wrapper {
		background-color: var(--surface-card) !important;
	}

	.pro-arrow {
		border-color: var(--text-color) !important;
	}

	.popper-inner {
		background-color: var(--surface-card) !important;
		border: 1px solid var(--surface-border)
	}

	.pro-menu a {
		color: var(--text-color)
	}
}